<template>
  <div id="edit-import" class="vasion-white-background">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div id="edit-import-header">
      <span id="header">{{ schedulerTitle }}</span>

      <VasionButton
        id="save-button"
        class="add-cancel"
        name="save-button"
        classProp="primary"
        :isDisabled="disableSaveButton"
        @vasionButtonClicked="save()"
      >
        Save
      </VasionButton>

      <VasionButton
        id="cancel-button"
        class="add-cancel"
        name="cancel-button"
        classProp="secondary"
        @vasionButtonClicked="cancel()"
      >
        Cancel
      </VasionButton>
    </div>

    <div id="main-section">
      <div id="import-details">
        <AutomationEngineSharedHeader
          ref="header"
          :nameLabel="'IMPORT NAME'"
          @permissions="showMainSection = false"
          @general-automation="showMainSection = true"
          @dirty="isDirty = true"
        />

        <!-- Build Import -->
        <div v-if="showMainSection" id="build-import" class="collapsable-section">
          <h2 class="subheader">
            Build Import
          </h2>

          <VasionButton
            name="collapse-section"
            class="collapse-section"
            :icon="showBuildImport ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
            title="Collapse Section"
            @vasionButtonClicked="toggleCollapsableSection('build-import')"
          />

          <div v-show="showBuildImport">
            <div class="row">
              <VasionInput
                id="from-folder"
                v-model="fromFolder"
                class="row-field"
                title="FOLDER PATH"
                inputType="top-white"
                name="from-folder"
                placeholder="Enter Folder Path..."
                :width="'396'"
                @input="isDirty = true"
              />

              <VasionInput
                id="import-to"
                v-model="toFolder"
                class="row-field browse-input"
                title="IMPORT TO"
                inputType="top-white"
                name="import-to"
                placeholder="Select Folder..."
                :width="'306'"
              />
              <VasionButton
                id="import-to-folder-button"
                class="browse-button"
                :classProp="'primary'"
                @vasionButtonClicked="toggleBrowseFolderDialog()"
              >
                Browse
              </VasionButton>

              <VasionDropList
                v-slot="slotItem"
                v-model="sendToWorkflow"
                class="row-field"
                :dataArray="workflowList"
                width="380"
                title="SEND TO WORKFLOW"
                placeholder="Select Workflow..."
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionDropList
                v-slot="slotItem"
                v-model="attributeForm"
                class="row-field"
                :dataArray="attributeFormList"
                width="380"
                :title="`${$formsLabel.toUpperCase()}`"
                :placeholder="`Select ${$formsLabel}...`"
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>

            <div class="row">
              <VasionCheckbox
                id="document-name-attributing"
                name="document-name-attributing"
                class="import-checkbox"
                :checked="documentNameAttributing"
                @change="toggleCheckbox('documentNameAttributing')"
              >
                Document Name Attributing
              </VasionCheckbox>

              <VasionCheckbox
                id="ocr-document"
                name="ocr-document"
                class="import-checkbox"
                :checked="ocrDocument"
                @change="toggleCheckbox('ocrDocument')"
              >
                OCR Document
              </VasionCheckbox>
            </div>

            <div v-if="documentNameAttributing" class="row">
              <VasionDropList
                v-slot="slotItem"
                v-model="documentNameAttribute"
                class="row-field"
                :dataArray="documentNameAttributingList"
                width="396"
                title="NAME ATTRIBUTING PROCESS"
                placeholder="Select Attribute..."
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>

            <div class="row">
              <VasionCheckbox
                id="delete-files-upon-import"
                name="delete-files-upon-import"
                class="import-checkbox"
                :checked="deleteFilesUponImport"
                @change="toggleCheckbox('deleteFilesUponImport')"
              >
                Delete Files Upon Import
              </VasionCheckbox>

              <VasionCheckbox
                id="only-import-new-files"
                name="only-import-new-files"
                class="import-checkbox"
                :checked="onlyImportNewFiles"
                @change="toggleCheckbox('onlyImportNewFiles')"
              >
                Only Import New Files
              </VasionCheckbox>

              <VasionCheckbox
                id="use-field-based-attributing"
                name="use-field-based-attributing"
                class="import-checkbox"
                :checked="useFieldBasedAttributing"
                @change="toggleCheckbox('useFieldBasedAttributing')"
              >
                Use Field-based Attributing
              </VasionCheckbox>

              <VasionCheckbox
                id="use-form-mapping"
                name="use-form-mapping"
                class="import-checkbox"
                :checked="useAttributeFormMapping"
                @change="toggleCheckbox('useAttributeFormMapping')"
              >
                Use {{ $formsLabel }} Mapping
              </VasionCheckbox>
            </div>

            <div class="row">
              <VasionCheckbox
                id="internal-lookup-process"
                name="internal-lookup-process"
                class="import-checkbox"
                :checked="internalLookupProcess"
                @change="toggleCheckbox('internalLookupProcess')"
              >
                Internal Lookup Process
              </VasionCheckbox>

              <VasionCheckbox
                id="import-structure-in-selected-folder"
                name="import-structure-in-selected-folder"
                class="import-checkbox"
                :checked="importStructureInSelectedFolder"
                @change="toggleCheckbox('importStructureInSelectedFolder'); inlineValidate('import-structure-in-selected-folder');"
              >
                Import Structure In Selected Folder
              </VasionCheckbox>

              <VasionCheckbox
                id="import-structure-below-selected-folder"
                name="import-structure-below-selected-folder"
                class="import-checkbox"
                :checked="importStructureBelowSelectedFolder"
                @change="toggleCheckbox('importStructureBelowSelectedFolder'); inlineValidate('import-structure-below-selected-folder');"
              >
                Import Structure Below Folder
              </VasionCheckbox>

              <VasionCheckbox
                id="dont-create-subfolders"
                name="dont-create-subfolders"
                class="import-checkbox"
                :checked="dontCreateSubfolders"
                @change="toggleCheckbox('dontCreateSubfolders')"
              >
                Don't Create Subfolders
              </VasionCheckbox>
            </div>
          </div>
        </div>

        <!-- Barcode Attributing -->
        <div v-if="showMainSection" id="barcode-attributing" class="collapsable-section">
          <h2 class="subheader">
            Barcode Attributing
          </h2>

          <VasionButton
            name="collapse-section"
            class="collapse-section"
            :icon="showBarcodeAttributing ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
            title="Collapse Section"
            @vasionButtonClicked="toggleCollapsableSection('barcode-attributing')"
          />

          <div v-show="showBarcodeAttributing">
            <div class="row">
              <VasionCheckbox
                id="read-bardcode"
                name="read-bardcode"
                class="import-checkbox"
                :checked="readBarcode"
                @change="toggleCheckbox('readBarcode')"
              >
                Read Barcode
              </VasionCheckbox>

              <VasionCheckbox
                id="read-only-first-page"
                name="read-only-first-page"
                class="import-checkbox"
                :checked="readOnlyFirstPage"
                @change="toggleCheckbox('readOnlyFirstPage'); inlineValidate('read-only-first-page');"
              >
                Read Only First Page
              </VasionCheckbox>

              <VasionCheckbox
                id="barcode-on-last-page"
                name="barcode-on-last-page"
                class="import-checkbox"
                :checked="barcodeOnLastPage"
                @change="toggleCheckbox('barcodeOnLastPage'); inlineValidate('barcode-on-last-page');"
              >
                Barcode On Last Page
              </VasionCheckbox>
            </div>

            <div class="row">
              <VasionCheckbox
                id="keep-barcode-page"
                name="keep-barcode-page"
                class="import-checkbox"
                :checked="keepBarcodePage"
                @change="toggleCheckbox('keepBarcodePage')"
              >
                Keep Barcode Page
              </VasionCheckbox>

              <VasionCheckbox
                id="use-barcode-value-as-name"
                name="use-barcode-value-as-name"
                class="import-checkbox"
                :checked="useBarcodeValueAsName"
                @change="toggleCheckbox('useBarcodeValueAsName')"
              >
                Use Barcode Value As Name
              </VasionCheckbox>
            </div>

            <div v-if="readBarcode" class="row">
              <VasionDropList
                v-slot="slotItem"
                v-model="indexing"
                class="row-field"
                :dataArray="barcodeIndexingList"
                width="396"
                title="BARCODE ATTRIBUTING"
                placeholder="Select Attributing..."
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionInput
                id="prefix"
                v-model="prefix"
                class="row-field"
                title="PREFIX"
                inputType="top-white"
                name="prefix"
                placeholder="Enter Prefix..."
                :width="'396'"
                @input="isDirty = true"
              />
            </div>

            <div class="row">
              <VasionCheckbox
                id="user-bardcode-splitter"
                name="user-bardcode-splitter"
                class="import-checkbox"
                :checked="useBarcodeSplitter"
                @change="toggleCheckbox('useBarcodeSplitter'); inlineValidate('user-bardcode-splitter');"
              >
                User Barcode Splitter
              </VasionCheckbox>
            </div>

            <div v-if="useBarcodeSplitter" class="row">
              <VasionDropList
                v-slot="slotItem"
                v-model="splitterOption"
                class="row-field"
                :dataArray="splitterOptionList"
                width="396"
                title="SPLITTER OPTION"
                placeholder="Select Splitter Option..."
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>

            <div v-if="useBarcodeSplitter" class="row">
              <VasionDropList
                v-if="shouldValuesDropListShow"
                v-slot="slotItem"
                v-model="userDefinedValue"
                class="row-field"
                :dataArray="valueList"
                width="340"
                title="VALUES"
                placeholder="Select Value..."
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionInput
                v-if="shouldEnterNewValueShow"
                id="new-value"
                v-model="newValue"
                class="row-field"
                title="NEW VALUE"
                inputType="top-white"
                name="new-value"
                placeholder="Enter New Value..."
                :width="'365'"
                @input="isDirty = true"
              />

              <VasionButton
                v-if="shouldAddCircleShow"
                name="add-value"
                :icon="'VasionAddCircleIcon'"
                title="Add Value"
                class="value-button"
                @vasionButtonClicked="beginAddValueClick()"
              />

              <VasionButton
                v-if="shouldRemoveCircleShow"
                name="remove-value"
                :icon="'VasionRemoveCircleIcon'"
                title="Remove Value"
                class="value-button"
                @vasionButtonClicked="removeValueClick()"
              />

              <VasionButton
                v-if="shouldSaveValueShow"
                name="add-value"
                title="Save Value"
                :classProp="'secondary'"
                class="value-button add-value-button"
                @vasionButtonClicked="addValueClick()"
              >
                Add Value
              </VasionButton>

              <VasionInput
                v-if="splitterOption && splitterOption !== '' && splitterOption.value === 1"
                id="value-from"
                v-model="valueFrom"
                class="row-field"
                title="VALUE FROM"
                inputType="top-white"
                name="value-from"
                placeholder="Enter Value..."
                :width="'190'"
                type="number"
                min="1"
                @input="isDirty = true"
              />

              <VasionInput
                v-if="splitterOption && splitterOption !== '' && splitterOption.value === 1"
                id="value-to"
                v-model="valueTo"
                class="row-field"
                title="VALUE TO"
                inputType="top-white"
                name="value-to"
                placeholder="Enter Value..."
                :width="'190'"
                type="number"
                min="1"
                @input="isDirty = true"
              />
            </div>

            <div class="row">
              <VasionCheckbox
                id="split-on-page-count"
                name="split-on-page-count"
                class="import-checkbox"
                :checked="splitOnPageCount"
                @change="toggleCheckbox('splitOnPageCount'); inlineValidate('split-on-page-count');"
              >
                Split On Page Count
              </VasionCheckbox>
            </div>
            <div v-if="splitOnPageCount" class="row">
              <VasionInput
                id="page-count"
                v-model="pageCount"
                class="row-field"
                title="SPLIT ON PAGE COUNT"
                inputType="top-white"
                name="page-count"
                placeholder="Enter Page Count..."
                :width="'396'"
                type="number"
                min="0"
                @input="isDirty = true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <md-dialog id="browse-folders-dialog" :md-active.sync="showBrowseFoldersDialog">
      <VasionFolderSelect
        title="Select Import Folder..."
        okButtonText="Select"
        @cancelButtonClick="toggleBrowseFolderDialog"
        @okButtonClick="folderDialogOK"
      />
    </md-dialog>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="import-snack"
      :closeOnClick="true"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
/* eslint-disable valid-typeof */
/* eslint-disable no-mixed-operators */

import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'EditImport',
  components: {
    AutomationEngineSharedHeader,
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      addingNewValue: false,
      attributeForm: '',
      barcodeOnLastPage: false,
      deleteFilesUponImport: false,
      documentNameAttribute: '',
      documentNameAttributing: false,
      dontCreateSubfolders: false,
      emailWhenFinished: '',
      folderId: null,
      fromFolder: '',
      importId: 0,
      importStructureBelowSelectedFolder: false,
      importStructureInSelectedFolder: false,
      indexing: '',
      internalLookupProcess: false,
      isDirty: false,
      isLoading: false,
      keepBarcodePage: false,
      loaderBackgroundColor,
      loaderColor,
      newValue: '',
      ocrDocument: false,
      onlyImportNewFiles: false,
      pageCount: '',
      prefix: '',
      readBarcode: false,
      readOnlyFirstPage: false,
      saveLocked: false,
      schedulerTitle: '',
      sendToWorkflow: '',
      showBarcodeAttributing: false,
      showBrowseFoldersDialog: false,
      showBuildImport: false,
      showLeaveDialog: false,
      showMainSection: true,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      splitOnPageCount: false,
      splitterOption: '',
      toFolder: '',
      useAttributeFormMapping: false,
      useBarcodeValueAsName: false,
      useBarcodeSplitter: false,
      useFieldBasedAttributing: false,
      userDefinedValue: '',
      valueFrom: '',
      valueList: [],
      valueTo: '',
    }
  },
  computed: {
    activeScheduler() { return this.$store.state.automationEngine.activeScheduler },
    attributeFormList() {
      return this.$store.state.attributeForm.forms.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    barcodeIndexingList() {
      return this.$store.state.mainViews.allBarcodeIndex.BIList.map((b) => {
        return {
          name: b.ConfigName,
          value: b.ID,
        }
      })
    },
    disableSaveButton() { return this.saveLocked || !this.isDirty },
    documentNameAttributingList() {
      return this.$store.state.automationEngine.documentNameIndexingList.map((i) => {
        return {
          name: i.sName,
          value: i.iID,
        }
      })
    },
    selectedFolder() { return this.$store.state.common.selectedFolder },
    shouldAddCircleShow() {
      return (this.splitterOption && this.splitterOption !== '') // The Splitter Options array exists and is populated
      && (this.splitterOption.value === 2 || this.splitterOption.value === 3) // "Values" or "Starts With" has been selected in Splitter Options
      && ((typeof this.valueList !== undefined && this.valueList.length !== 0) && !this.addingNewValue) // We have values in valueList, and the user isn't currently already adding one.
    },
    shouldEnterNewValueShow() {
      return (this.splitterOption && this.splitterOption !== '') // The Splitter Options array exists and is populated
      && (this.splitterOption.value === 2 || this.splitterOption.value === 3) // "Values" or "Starts With" has been selected in Splitter Options
      && (typeof this.valueList === undefined || this.valueList.length === 0 // There are not yet any values in valueList
        || this.addingNewValue) // The user currently wants to enter a new value
    },
    shouldRemoveCircleShow() {
      return (this.splitterOption && this.splitterOption !== '') // The Splitter Options array exists and is populated
      && (this.splitterOption.value === 2 || this.splitterOption.value === 3) // "Values" or "Starts With" has been selected in Splitter Options
      && (typeof this.valueList !== undefined && this.valueList !== '' && this.valueList.length > 0) // There is a value to remove
      && !this.addingNewValue // The user is not currently adding a new value
    },
    shouldSaveValueShow() {
      return (this.splitterOption && this.splitterOption !== '') // The Splitter Options array exists and is populated
      && (this.splitterOption.value === 2 || this.splitterOption.value === 3) // "Values" or "Starts With" has been selected in Splitter Options
      && (
        ((typeof this.valueList === undefined || this.valueList.length === 0) && this.newValue !== '') // We have no values in valueList yet, so let the user add the first one, if non-empty
        || (this.addingNewValue && this.newValue !== '') // The user is currently trying to add a new, non-empty value
      )
    },
    shouldValuesDropListShow() {
      return (this.splitterOption && this.splitterOption !== '') // The Splitter Options array exists and is populated
      && (this.splitterOption.value === 2 || this.splitterOption.value === 3) // "Values" or "Starts With" has been selected in Splitter Options
      && !this.addingNewValue // The user is not currently adding a new value
      && this.valueList && this.valueList !== '' // valueList is an array
    },
    splitterOptionList() {
      return [
        { name: 'Range Value', value: 1 },
        { name: 'Start With', value: 2 },
        { name: 'Values', value: 3 },
        { name: 'Advance Barcode', value: 4 },
        { name: 'Value Changed', value: 5 }]
    },
    workflowList() { return this.$store.state.reporting.workflows },
  },
  watch: {
    pageCount() {
      if (parseInt(this.pageCount, 10) < 0) {
        this.pageCount = 0
      }
    },
    valueFrom() {
      if (parseInt(this.valueFrom, 10) < 1) {
        this.valueFrom = 1
      }
    },
    valueTo() {
      if (parseInt(this.valueTo, 10) < 1) {
        this.valueTo = 1
      }
    },
  },
  async created() {
    this.isLoading = true

    await Promise.all([
      this.$store.dispatch('automationEngine/clearActiveScheduler'),
      this.$store.dispatch('attributeForm/getForms'),
      this.$store.dispatch('reporting/getWorkflows'),
      this.$store.dispatch('automationEngine/getDocumentNameIndexingList'),
      this.$store.dispatch('mainViews/getAllBarcodeIndex'),
    ])

    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      await this.$store.dispatch('automationEngine/getImportDetails', parseInt(this.$route.params.schedulerId, 10))
      if (this.activeScheduler) {
        this.setImportValues()
      }
    }
    this.setHeaderDetails(this.importId, this.activeScheduler?.runDetails)

    this.schedulerTitle = this.importId && this.importId > 0 && this.activeScheduler.runDetails ? this.activeScheduler.runDetails.name : 'Untitled Import'

    this.isLoading = false
  },
  methods: {
    addValueClick() {
      if (this.newValue !== '') {
        if (this.valueList === '') {
          this.valueList = []
        }
        const index = this.valueList.findIndex((v) => v.name === this.newValue)
        if (index > -1) {
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'The value cannot be added because it already exists.'
          this.snackbarImage = false
        } else {
          this.isDirty = true
          this.valueList.push({ name: this.newValue, value: this.newValue })
          this.newValue = ''
          this.addingNewValue = false
        }
      } else {
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Please enter a value before clicking "Add Value".'
        this.snackbarImage = false
      }
      this.showSnackbarBool = true
    },
    beginAddValueClick() { this.addingNewValue = true },
    cancel() { this.$router.push({ name: 'ImportExport' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    folderDialogOK(payload) {
      this.isDirty = true
      this.folderId = payload.SelectedFolderId
      this.toFolder = payload.SelectedFolderName

      this.toggleBrowseFolderDialog()
    },
    inlineValidate(fieldId) {
      let error = false
      let errorText = ''
      this.snackbarTitle = 'ERROR'
      this.snackbarImage = false

      if (fieldId === 'import-structure-in-selected-folder' || fieldId === 'import-structure-below-selected-folder') {
        if (this.importStructureInSelectedFolder && this.importStructureBelowSelectedFolder) {
          error = true
          errorText += '- "Import Structure In Selected Folder" and "Import Structure Below Selected Folder" cannot both be true.\n'
        }
      }

      if (fieldId === 'user-bardcode-splitter' || fieldId === 'split-on-page-count') {
        if (this.useBarcodeSplitter && this.splitOnPageCount) {
          error = true
          errorText += '- "User Barcode Splitter" and "Split On Page Count" cannot both be true.\n'
        }
      }

      if (fieldId === 'read-only-first-page' || fieldId === 'barcode-on-last-page') {
        if (this.readOnlyFirstPage && this.barcodeOnLastPage) {
          error = true
          errorText += '- "Read Only First Page" and "Barcode On Last" cannot both be true.\n'
        }
      }

      if (error) {
        this.snackbarSubTitle = errorText
        this.showSnackbarBool = true
        console.warn(errorText)
      }
    },
    removeValueClick() {
      const index = this.valueList.findIndex((v) => v.name === this.userDefinedValue.name)
      if (index > -1) {
        this.isDirty = true
        this.valueList.splice(index, 1)
      }
      this.userDefinedValue = ''
    },
    async save() {
      this.saveLocked = true
      const { header } = this.$refs
      if (await this.validate(header)) {
        const payload = {
          runDetails: await header.getValues(),
          importFromFolderPath: this.fromFolder,
          importToFolderID: this.folderId,
          importToFolderName: this.toFolder,
          workflowID: this.sendToWorkflow && this.sendToWorkflow !== '' ? this.sendToWorkflow.value : null,
          attributeformID: this.attributeForm && this.attributeForm !== '' ? this.attributeForm.value : null,
          performDocumentNameAttribute: this.documentNameAttributing,
          documentNameAttributeID: this.documentNameAttributing && this.documentNameAttribute && this.documentNameAttribute !== '' ? this.documentNameAttribute.value : null,
          deleteFilesUponImport: this.deleteFilesUponImport,
          importOnlyNewFiles: this.onlyImportNewFiles,
          useFieldBasedAttributing: this.useFieldBasedAttributing,
          internalLookupProcess: this.internalLookupProcess,
          importStructureInSelectedFolder: this.importStructureInSelectedFolder,
          importStructureBelowSelectedFolder: this.importStructureBelowSelectedFolder,
          dontCreateSubfolders: this.dontCreateSubfolders,
          useAttributeFormMapping: this.useAttributeFormMapping,
          ocrDocument: this.ocrDocument,
          useBarcodeSplitter: this.useBarcodeSplitter,
          readBarcode: this.readBarcode,
          readBarcodeFirstPageOnly: this.readOnlyFirstPage,
          keepBarcodePage: this.keepBarcodePage,
          barcodeOnLastPage: this.barcodeOnLastPage,
          useBarcodeValueAsName: this.useBarcodeValueAsName,
          barCodeAttributingID: this.readBarcode ? this.indexing.value : 0,
          prefix: this.readBarcode ? this.prefix : '',
          barcodeValues: this.valueList && this.valueList !== '' ? this.valueList.map(v => { return v.name }) : [],
          barcodeValueFrom: !this.valueFrom || this.valueFrom === '' ? 1 : this.valueFrom,
          barcodeValueTo: !this.valueTo || this.valueTo === '' ? 1 : this.valueTo,
          splitOnPageCount: this.splitOnPageCount,
          splitOnPageCountNumber: this.pageCount,
          barcodeSplitterType: this.useBarcodeSplitter ? this.splitterOption.name : '',
        }

        if (!await this.$store.dispatch('automationEngine/saveImportScheduler', payload)) {
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'There was an error saving the import.'
          this.snackbarImage = false
          this.saveLocked = false
        } else {
          this.isDirty = false
          this.snackbarTitle = 'SUCCESS'
          this.snackbarSubTitle = 'Import saved successfully.'
          this.snackbarImage = true
          this.saveLocked = false

          this.$router.push({ name: 'ImportExport' })
        }
        this.showSnackbarBool = true
      }
    },
    setHeaderDetails(id, details) {
      const { header } = this.$refs
      if (id > 0) {
        header.setValues(details)
      } else {
        header.setValues()
      }
    },
    setImportValues() {
        this.importId = this.activeScheduler.runDetails.schedulerID
        this.fromFolder = this.activeScheduler.importFromFolderPath
        this.folderId = this.activeScheduler.importToFolderID
        this.toFolder = this.activeScheduler.importToFolderName
        this.sendToWorkflow = this.workflowList.find(w => w.value === this.activeScheduler.workflowID) || ''
        this.attributeForm = this.attributeFormList.find(f => f.value === this.activeScheduler.attributeFormID) || ''
        this.documentNameAttributing = this.activeScheduler.performDocumentNameAttribute
        this.documentNameAttribute = this.documentNameAttributingList.find(d => d.value === this.activeScheduler.documentNameAttributeID) || ''
        this.deleteFilesUponImport = this.activeScheduler.deleteFilesUponImport
        this.onlyImportNewFiles = this.activeScheduler.importOnlyNewFiles
        this.useFieldBasedAttributing = this.activeScheduler.useFieldBasedAttributing
        this.internalLookupProcess = this.activeScheduler.internalLookupProcess
        this.importStructureInSelectedFolder = this.activeScheduler.importStructureInSelectedFolder
        this.importStructureBelowSelectedFolder = this.activeScheduler.importStructureBelowSelectedFolder
        this.dontCreateSubfolders = this.activeScheduler.dontCreateSubfolders
        this.useAttributeFormMapping = this.activeScheduler.useAttributeFormMapping
        this.ocrDocument = this.activeScheduler.ocrDocument

        this.useBarcodeSplitter = this.activeScheduler.useBarcodeSplitter
        this.readBarcode = this.activeScheduler.readBarcode
        this.readOnlyFirstPage = this.activeScheduler.readBarcodeFirstPageOnly
        this.keepBarcodePage = this.activeScheduler.keepBarcodePage
        this.barcodeOnLastPage = this.activeScheduler.barcodeOnLastPage
        this.useBarcodeValueAsName = this.activeScheduler.useBarcodeValueAsName
        this.indexing = this.barcodeIndexingList.find(i => i.value === this.activeScheduler.barcodeAttributingID) || ''
        this.prefix = this.activeScheduler.prefix
        this.splitterOption = this.splitterOptionList.find(o => o.name === this.activeScheduler.barcodeSplitterType) || ''
        this.valueList = this.activeScheduler.barcodeValues && this.activeScheduler.barcodeValues.length > 0
            ? this.activeScheduler.barcodeValues.map(v => { return { name: v, value: v } })
            : ''
        this.valueFrom = this.activeScheduler.barcodeValueFrom
        this.valueTo = this.activeScheduler.barcodeValueTo
        this.splitOnPageCount = this.activeScheduler.splitOnPageCount
        this.pageCount = this.activeScheduler.splitOnPageCountNumber
    },
    toggleBrowseFolderDialog() { this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog },
    toggleCheckbox(id) {
      this[id] = !this[id]
      this.isDirty = true
    },
    toggleCollapsableSection(section) {
      switch (section) {
        case 'build-import':
          this.showBuildImport = !this.showBuildImport
          break;
        case 'barcode-attributing':
          this.showBarcodeAttributing = !this.showBarcodeAttributing
          break;
        default:
          this.showBuildImport = false
          this.showBarcodeAttributing = false
          break;
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async validate(header) {
      let error = false
      let errorText = ''
      this.snackbarTitle = 'ERROR'
      this.snackbarImage = false

      await header.validate()
      if (header.errors !== '') {
        error = true
        errorText += `${header.errors}`
      }

      if (this.documentNameAttributing && this.documentNameAttribute === '') {
        error = true
        errorText += '- Please provide a value for "Name Attributing Process," since "Document Name Attributing" is enabled.\n'
      }

      if (this.importStructureInSelectedFolder && this.importStructureBelowSelectedFolder) {
        error = true
        errorText += '- Import Structure In Selected Folder" and "Import Structure Below Selected Folder" cannot both be true.\n'
      }

      if (this.useBarcodeSplitter && this.splitOnPageCount) {
        error = true
        errorText += '- User Barcode Splitter" and "Split On Page Count" cannot both be true.\n'
      }

      if (this.readOnlyFirstPage && this.barcodeOnLastPage) {
        error = true
        errorText += '- Read Only First Page" and "Barcode On Last" cannot both be true.\n'
      }

      if (this.splitOnPageCount && this.pageCount <= 0) {
        error = true
        errorText += '- Please provide a value greater than zero for "Split On Page Count."\n'
      }

      if (this.fromFolder === '') {
        error = true
        errorText += '- Please provide a value for "Folder."\n'
      } else if (!await this.$store.dispatch('automationEngine/getIsValidUncPath', { Value: this.fromFolder })) {
        error = true
        errorText += '- The value of "Folder" is not a valid UNC path.\n'
      }

      if (this.toFolder === '') {
        error = true
        errorText += '- Please provide a value for "Import To."\n'
      }

      if (this.useBarcodeSplitter && (this.splitterOption && this.splitterOption !== '') && (this.splitterOption.value === 2 || this.splitterOption.value === 3) && (!this.valueList || this.valueList.length === 0)) {
        error = true
        errorText += '- Please enter a value for "Values," since "User Barcode Splitter" is checked and Splitter Option of "Values" or "Starts With" is selected.\n'
      }

      if (this.useBarcodeSplitter && this.splitterOption && this.splitterOption !== '' && this.splitterOption.value === 1 && (this.valueFrom === '' || this.valueTo === '')) {
        error = true
        errorText += '- Please enter a value greater than zero for both "Value From" and "Value To," since "User Barcode Splitter" is checked and Splitter Option of "Range Value" is selected.\n'
      }

      if (error) {
        this.snackbarSubTitle = errorText
        this.showSnackbarBool = true
        console.warn(errorText)
        this.saveLocked = false
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #edit-import {
    width: 100%;
    margin: 15px 0px 0px 15px;

    #edit-import-header {
      width: calc(100vw - 310px);
      height: 48px;
      margin-bottom: 5px;

      #header {
        @include Headline;
      }

      .add-cancel {
        float: right;
      }
    }
    #import-to-folder-button {
      position: relative;
      top: 15px;
      margin-right: 5px;
    }
    #main-section {
      margin-top: 10px;

      #import-details {
        width: 100%;
        .row {
          width: 100%;
          margin: 13px 0;
          display: flex;

          .section-title {
            @include SECTION-HEADER;
            color: $grey-400;
            display: block;
            margin: 5px 0;
          }

          .subheader {
            @include SubHeadline;
          }

          .row-field {
            margin: 0 14px 5px 0;

            &.checkbox {
              width: 390px;
            }
          }

          .browse-input {
            margin-right: 5px;
          }

          .vasion-button {
            &.browse-button {
              button {
                margin-top: 22px;
                margin-left: 0;
                margin-right: 13px;
              }
            }

            &.value-button {
              float: left;
              margin-left: -15px;
              margin-right: 3px;
              margin-top: 20px;

              &.add-value-button {
                margin-top: 16px;
              }
            }
          }

          .import-checkbox {
            width: 408px;
          }
        }

        .collapsable-section {
          float: left;
          margin-top: 20px;
          width: 100%;

          .subheader {
            @include SubHeadline;
            width: 225px;
            display: block;
            float: left;
            margin-top: 5px;
          }

          .subtitle {
            @include Subtitle;
          }
        }
      }
    }
  }

  #browse-folders-dialog {
    .folder-dialog-browse {
      width: 400px;
      height: 300px;
      overflow: auto;
    }

    .align-right {
      text-align: right;
    }
  }
</style>
